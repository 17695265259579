import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./styles/footer-styles.css";
function Footer() {
  return (
    <div className="footer">
      <div className="footer-body">
        <div>
          <img className="bottom-logo" src="./assets/bottom.png" alt="" />
        </div>
        <div className="footer-text">
          <div className=" footer-text1">
            <a className="community-link" href="#overview">
              OVERVIEW
            </a>
          </div>
          <div>
            {" "}
            <a
              className="community-link"
              style={{ color: "white" }}
              href="#five-families"
            >
              COMMUNITY
            </a>
          </div>
          <div>
            {" "}
            <a
              className="community-link"
              style={{ color: "white" }}
              href="#Roadmap"
            >
              ROADMAP
            </a>
          </div>
        </div>
        <div className="footer-text">
          <div className=" footer-text1" style={{ visibility: "hidden" }}>
            OVERVIEW
          </div>
          <div>DISCORD</div>
          <div>TWITTER</div>
        </div>
      </div>
      {/* <div className="terms">
        <div>TERMS & CONDITIONS</div>
        <div>PRIVACY POLICY</div>
        <div>@ 2022 High Voltage Worlds, LLC</div>
      </div> */}
      <div style={{ width: "100%" }}>
        <Box className="terms2" sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid className="terms-text" item xs={12} md={12} lg={4}>
              <a style={{ color: "white" }} href="#terms">
                TERMS & CONDITIONS
              </a>
            </Grid>
            <Grid className="terms-text" item xs={12} md={12} lg={4}>
              <a style={{ color: "white" }} href="#privacy-policy">
                {" "}
                PRIVACY POLICY
              </a>
            </Grid>
            <Grid className="terms-text" item xs={12} md={12} lg={4}>
              @ 2022 High Voltage Worlds, LLC
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default Footer;
