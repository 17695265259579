import React from "react";
import Carousel from "./Carousel";
import Navigation from "./Navigation";
import Overview from "./Overview";
import FAQ from "./FAQ";
import FiveFamilies from "./FiveFamilies";
import "./styles/home-styles.css";
import "./styles/general-styles.css";
import Footer from "./Footer";
import Timeline from "./Timeline";
import Appbar from "./Appbar";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Ownership from "./Ownership";

const Home = () => {
  return (
    <div id="">
      <Appbar />
      <Navigation />
      <Carousel />
      <Overview />
      <FiveFamilies />
      <Timeline />
      <FAQ />
      <Footer />
      <Terms />
      <Ownership />
      <Privacy />
    </div>
  );
};

export default Home;
