import React from "react";
import "./styles/overview-styles.css";
const Overview = () => {
  return (
    <>
      <a href="#home" class="scroll-to-top w-inline-block">
        <div class="scroll-text">go &nbsp;top</div>
      </a>
      <div
        id="overview"
        className="main-outer-container d-flex justify-content-center row py-5 odd-section"
      >
        <header className="text-center mb-0 title1">
          WHAT IS HIGH VOLTAGE WORLDS?
        </header>
        <article className="text-center subheading mb-3">
          High Voltage Worlds is a collection of 10,000 NFTs. Holding an NFT
          gives you access to BTC mining profits and placement in 1 of 5
          families
        </article>

        <section className="container">
          <section
            style={{ textAlign: "left" }}
            className="row d-flex justify-content-center"
          >
            <section className="col-sm-6 mb-3">
              <header className="section-heading text-start">
                <div>
                  <span className="display-badge">1</span>
                </div>{" "}
                &nbsp;
                <div>PAYMENT SYSTEM</div>
              </header>
              <p className="section-content">
                Net profits from our Bitcoin mining will be split into 3 pools:
                Community, investment, and ??? pool. The Community Pool (%50)
                will be used to pay NFT holders monthly in Solana. The
                Investment Pool (45%) will go towards reinvesting into the
                project and covering overhead costs of High Voltage. The ???
                Pool (5%) will be revealed near mint.
              </p>
            </section>
            <section className="col-sm-6 mb-3">
              <header className="section-heading">
                <span className="display-badge">2</span>&nbsp;COMMUNITY
              </header>
              <p className="section-content">
                Minters will obtain an NFT belonging to 1 of 5 families. Owning
                an NFT will give you access to family-exclusive Discord servers,
                DAOs, merchandise, airdrops, giveaways, and community events. In
                these events, holders can participate against other families to
                win huge Solana prizes funded by High Voltage and royalties from
                secondary sales..
              </p>
            </section>
          </section>
          <section
            style={{ textAlign: "left" }}
            className="row d-flex justify-content-center"
          >
            <section className="col-sm-12 mb-3">
              <header className="section-heading">
                <span className="display-badge">3</span>&nbsp;BITCOIN MINING
              </header>
              <p className="section-content third-paragraph">
                HVW will use profits from the mint to purchase Bitcoin miners.
                These miners will be hosted by Vulcan Mining and a second
                undisclosed hosting facility.
              </p>
            </section>
          </section>
        </section>
      </div>
    </>
  );
};

export default Overview;
