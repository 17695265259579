import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import plus from "./Frame 504.svg";
import minus from "./Frame 505.svg";

import { Grid } from "@mui/material";
import "./styles/faq-styles.css";
const FAQ = () => {
  const [style, setStyle] = React.useState(0);

  const changeStyle = () => {
    setStyle(!style);
  };
  const [style2, setStyle2] = React.useState(0);

  const changeStyle2 = () => {
    setStyle2(!style2);
  };
  const [style3, setStyle3] = React.useState(0);

  const changeStyle3 = () => {
    setStyle3(!style3);
  };
  const [style4, setStyle4] = React.useState(0);

  const changeStyle4 = () => {
    setStyle4(!style4);
  };
  const [style5, setStyle5] = React.useState(0);

  const changeStyle5 = () => {
    setStyle5(!style5);
  };

  const [style6, setStyle6] = React.useState(0);

  const changeStyle6 = () => {
    setStyle6(!style6);
  };

  const [style7, setStyle7] = React.useState(0);

  const changeStyle7 = () => {
    setStyle7(!style7);
  };
  return (
    <div
      id="faq"
      className="main-outer-container d-flex justify-content-center row py-5 even-section"
    >
      <header className="text-center mb-3 text-uppercase title2">
        High Voltage Worlds
      </header>
      <article className="text-center subheading mb-5 col-12">
        We’re here to answer any questions you have about HV Worlds! Here are
        some of the most frequently asked questions:
      </article>
      <Grid className="grid" container alignItems="center" spacing={2}>
        <Grid style={{ textAlign: "center" }} item xs={12} md={12}>
          <Accordion
            style={{ border: "1px solid black", backgroundColor: "white" }}
          >
            <AccordionSummary
              className={style ? "cont faq" : "cont2 faq"}
              onClick={changeStyle}
              expandIcon={
                !style ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={style ? "text1" : "text2"}>
                What is High Voltage Worlds?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                High Voltage Worlds is a collection of 10,000 NFTs built on the
                Solana Blockchain. Holding our NFTs will to the payments of the
                community pool based on your NFTs rarity, and grants you access
                to family exclusive perks such as community events, Discord
                servers, and airdrops.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ border: "1px solid black", backgroundColor: "white" }}
          >
            <AccordionSummary
              className={style2 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle2}
              expandIcon={
                !style2 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style2 ? "text1" : "text2"}>
                What makes HV Worlds special?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                High Voltage Worlds is a utility NFT on the Solana blockchain
                that pays holders Bitcoin mining profits monthly. Our project
                bridges the gap between cryptocurrency mining, Defi, DAOs, and
                NFTs. High Voltage Worlds has real utility that links the holder
                to real world assets. This, combined with our unique,
                community-centered design, makes our project a leader in the NFT
                space.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ border: "1px solid black", backgroundColor: "white" }}
          >
            <AccordionSummary
              className={style3 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle3}
              expandIcon={
                !style3 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style3 ? "text1" : "text2"}>
                What is Vulcan Mining?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                Vulcan Mining was founded in March of 2021 by hvJericho,
                hvPerseus, hvHermes, and 4 other partners. Initially started as
                just a hobby in a small 2-bedroom apartment, Vulcan has since
                acquired a dedicated mining facility and has access to over 40
                acres of unworked land. Vulcan has a large fleet of GPU miners
                and ASIC miners with the capacity to host a large number of
                additional miners. Vulcan is 1 of the 2 hosting facilities
                partnered with High Voltage Worlds.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <AccordionSummary
              className={style4 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle4}
              expandIcon={
                !style4 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style4 ? "text1" : "text2"}>
                How do I buy your NFT? What am I buying?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">TBA</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <AccordionSummary
              className={style5 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle5}
              expandIcon={
                !style5 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style5 ? "text1" : "text2"}>
                How can I prepare for the drop?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                You'll need the basics:<br></br> • A Solana Wallet – we
                recommend Phantom
                <br></br>• 3 SOL in your wallet, plus some extra SOL to fund
                transaction costs (gas). Gas is an SOL network fee, and we take
                none of it.<br></br>• Set a timer to be ready for the drop, and
                make sure to follow our{" "}
                <a href="https://twitter.com/hvWorldsNFT_">Twitter</a> for
                updates
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <AccordionSummary
              className={style6 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle6}
              expandIcon={
                !style6 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style6 ? "text1" : "text2"}>
                How do I join a family once I buy an NFT?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">TBA</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <AccordionSummary
              className={style7 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle7}
              expandIcon={
                !style7 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style7 ? "text1" : "text2"}>
                Any other questions? Reach out!
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                We're here to answer any questions you may have about High
                Voltage Worlds! Reach out on any of the following channels:
                Twitter:{" "}
                <a href="https://twitter.com/hvWorldsNFT_">@misfituni</a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQ;
