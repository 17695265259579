import React from "react";
import "./styles/appbar-styles.css";
import list from "./menu.png";
import close from "./close (1).png";

function Appbar() {
  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  return (
    <>
      <div id="home">
        <div
          className={click ? "main-container" : ""}
          onClick={() => Close()}
        />
        <nav className="navbar" onClick={(e) => e.stopPropagation()}>
          <div className="nav-container">
            <div>
              <div className="nav__width">
                <a href="/" className="nav-logo nav-wallet-button ">
                  CONNECT WALLET
                </a>
              </div>
            </div>

            <div>
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  <a
                    href="/"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#overview"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    Overview
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#five-families"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    5 Families
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Roadmap"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    ROADMAP
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#faq"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Roadmap"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <img
                      className="socials-nav"
                      src="./assets/icons8-twitter.svg"
                      alt=""
                    />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#Roadmap"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    <img
                      className="socials-nav"
                      src="./assets/icons8-discord-new.svg"
                      alt=""
                    />
                  </a>
                </li>

                {/* <li className="nav-item">
                  <a
                    href="https://mint.alien-society.com/"
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    MINT
                  </a>
                </li> */}
              </ul>
            </div>
            <div onClick={handleClick}>
              <img
                src={click ? close : list}
                style={{ color: "white", width: "16px", height: "16px" }}
                className={click ? "nav-icon2" : "nav-icon"}
              ></img>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Appbar;
