import React from "react";
import "./styles/navigation-styles.css";
import gif from "./bkggif.gif";
import gif2 from "./bkggif2.gif";
import MediaQuery from "react-responsive";
const Navigation = () => {
  return (
    <>
      <MediaQuery maxWidth={1024}>
        <img className="bkg" src={gif2} alt="" />
      </MediaQuery>
      <MediaQuery minWidth={1025}>
        <img className="bkg" src={gif} alt="" />
      </MediaQuery>
      <div className="mintContainer">
        <div className="center">
          <div className="title3">HIGH VOLTAGE WORLDS</div>
          <div className="text-navigation">
            The first Solana NFT paying holders Bitcoin mining profits.
            <br></br>5 families, 1 community.&nbsp;&nbsp;Our utility, your
            world.
          </div>

          <div className="links">MINT</div>
          <div className="landing-display-5-images">
            <div className="landing-images">
              <div>
                <img
                  className="landing-iamge1"
                  src="./assets/The 5 Families/Cryptopunk.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="landing-iamge1"
                  src="./assets/The 5 Families/Penguin.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="landing-iamge1"
                  src="./assets/The 5 Families/Masks.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  className="landing-iamge1"
                  src="./assets/The 5 Families/Cat.png"
                  alt=""
                />
              </div>

              <div>
                <img
                  className="landing-iamge1"
                  src="./assets/The 5 Families/Ape.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
