import { Box, Grid } from "@mui/material";
import React from "react";
import "./styles/timeline-styles.css";

function Timeline() {
  return (
    <div className="timeline" id="Roadmap">
      <section id="roadmap" class="section-5 wf-section">
        <div class="container-5 w-container">
          <div class="section-6 cc-no-padding-bottom">
            <div class="container-4 cc-flex-h">
              <div
                id="w-node-f48f88cd-be7a-a1c0-c8de-4c93918e1ff2-c3cc247c"
                class="col-left"
              >
                <div class="learn_wrapper">
                  <p className="timeline-title">HIGHT VOLTAGE ROADMAP</p>
                  <h4 class="sub-title-text text-center timeline-subheading2 tiitlesrevsdf editagain whitepara roadmappaara subheaderforrm">
                    Our mission is to power the decentralization of humanity. As
                    our community grows, so does our conviction to deliver on
                    our promises. Join us on our journey.
                  </h4>
                </div>
              </div>
              <div
                id="w-node-f48f88cd-be7a-a1c0-c8de-4c93918e1ff8-c3cc247c"
                class="col-right"
                style={{ marginTop: "50px", marginBottom: "50px" }}
              >
                <div
                  data-w-id="f48f88cd-be7a-a1c0-c8de-4c93918e1ff9"
                  class="block-wrapper"
                >
                  <div tabindex="0" class="block _1">
                    <div class="div-block-10">
                      <div class="block-title">Phase 1 - Q1 2022</div>
                      <div class="block-text">HVW NFT</div>
                      <h4 class="sub-title-text tiitlesrevsdf editagain whitepara roadmappaara cardsthing">
                        <div>Discord Test Launch</div>
                        <div>Presale 1 and 2</div>
                        <div>Official mint</div>
                        <div>“The First Election”</div>
                        <div>Donation to charity</div>
                        <div>ASIC miners online</div>
                      </h4>
                    </div>
                  </div>
                  <div tabindex="0" class="block _2">
                    <div class="div-block-10">
                      <div class="block-title">Phase 2 - Q2 2022</div>
                      <div class="block-text">HVW Community</div>
                      <h4 class="sub-title-text tiitlesrevsdf editagain whitepara roadmappaara cardsthing">
                        <div>1st HVW holder payment</div>
                        <div>HVW monthly community events</div>
                        <div>Lore implementation</div>
                      </h4>
                    </div>
                  </div>
                  <div tabindex="0" class="block _3">
                    <div class="div-block-10">
                      <div class="block-title">Phase 3 - Q3 2022</div>
                      <div class="block-text">HV Crypto Expansion</div>
                      <h4 class="sub-title-text tiitlesrevsdf editagain whitepara roadmappaara cardsthing">
                        <div>Unveil High Voltage DAO and treasury</div>
                        <div>HV token development</div>
                        <div>HV layer 2 blockchain</div>
                      </h4>
                    </div>
                  </div>
                  <div tabindex="0" class="block _3 _4tho">
                    <div class="div-block-10">
                      <div class="block-title">Phase 4 - Q4 2022</div>
                      <div class="block-text">HV 2nd NFT Project</div>
                      <h4 class="sub-title-text tiitlesrevsdf editagain whitepara roadmappaara cardsthing">
                        <div>Masterwork NFT</div>
                        <div>Unveil 2nd HV NFT collection</div>
                        <div>Doxx HV founders</div>
                        <div>1st in-person community event</div>
                      </h4>
                    </div>
                  </div>
                  <div tabindex="0" class="block _3 _5tho">
                    <div class="div-block-10">
                      <div class="block-title">Phase 5 - Q1 2023</div>
                      <div class="block-text">Releasing HV DAO and 2nd NFT</div>
                      <h4 class="sub-title-text tiitlesrevsdf editagain whitepara roadmappaara cardsthing">
                        <div>2nd NFT collection</div>
                        <div>HighVoltage DAO beta</div>
                        <div>HighVoltage DAO liquidity building event</div>
                      </h4>
                    </div>
                  </div>
                  <div tabindex="0" class="block _4">
                    <div class="div-block-10">
                      <div class="block-title">Phase 6 - Q2 2023</div>
                      <div class="block-text">TBA</div>
                      <h4 class="sub-title-text tiitlesrevsdf editagain whitepara roadmappaara cardsthing">
                        <div>TBA</div>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Timeline;
