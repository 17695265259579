import React from "react";
import "./styles/terms-styles.css";
function Ownership() {
  return (
    <div
      id="ownership"
      className="main-outer-container d-flex justify-content-center row py-5 even-section"
    >
      <header
        style={{ color: "white" }}
        className="text-center mb-3 text-uppercase title2"
      >
        OWNERSHIP & LICENSE
      </header>
      <article className="text-center subheading privacy-heading mb-5 col-12">
        i. NFT OWNERSHIP
      </article>
      <article className="text-center subheading mb-5 col-12">
        Each hvWorlds is an NFT (Non-Fungible Token) on the Solana blockchain.
        When you purchase a hvWorlds NFT, you own the underlying Art of the
        hvWorlds NFT. Ownership of the hvWorlds NFT is mediated entirely by the
        Smart Contract and the Solana Network as a whole. At no point may we
        seize, freeze, delete, or otherwise modify the ownership of any hvWorlds
        NFT.
      </article>

      <article className="text-center subheading privacy-heading mb-5 col-12">
        ii. PERSONAL USE
      </article>
      <article className="text-center subheading mb-5 col-12">
        Subject to your continued compliance with these Terms, High Voltage
        Worlds, LLC grants you a worldwide, royalty-free license to use, copy,
        and display the purchased Art, along with any extensions that you choose
        to create or use, solely for the following purposes:
      </article>
      <article className="text-center subheading terms-bold mb-5 col-12">
        <ol style={{ textAlign: "left" }}>
          <li>for your own personal, non-commercial use;</li>
          <li>
            as part of a marketplace that permits the purchase and sale of your
            hvWorlds NFT, provided that the marketplace cryptographically
            verifies each hvWorlds NFT owner’s rights to display the Art for
            their hvWorlds NFT to ensure that only the actual owner can display
            the Art; or
          </li>

          <li>
            as part of a third-party website or application that permits the
            inclusion, involvement, or participation of your hvWorlds NFT,
            provided that the website/application cryptographically verifies
            each hvWorlds NFT owner’s rights to display the Art for their
            hvWorlds NFT to ensure that only the actual owner can display the
            Art, and provided that the Art is no longer visible once the owner
            of the hvWorlds NFT leaves the website/application. High Voltage
            Worlds, LLC and its officers retain the right to revoke Personal Use
            rights and license if the usage is determined to be obscene,
            illegal, detrimental to the community, or detrimental to the High
            Voltage Worlds brand.
          </li>
        </ol>
      </article>
      <article className="text-center subheading privacy-heading mb-5 col-12">
        ii. PERSONAL USE
      </article>
      <article className="text-center subheading mb-5 terms-bold  col-12">
        Subject to your continued compliance with these Terms, High Voltage
        Worlds, LLC grants you an unlimited, worldwide license to use, and to
        display, the purchased Art for the purpose of creating derivative works
        based upon the Art (“Commercial Use”). hvWorlds NFT holders are given
        commercial rights to their specific hvWorlds NFT. You can sell its
        likeness or make derivatives of it. You can give others permission to
        use it within these same compliance Terms. An example of such Commercial
        Use would be the use of the Art to produce and sell merchandise products
        (T-Shirts, etc.) displaying copies of the Art. However, High Voltage
        Worlds prohibits commercial use of our logo, names (including but not
        limited to: "hvWorlds", "hvWorld", "High Voltage Worlds", and "HVW"),
        website images, and marketing material. Our logos, names, and images
        related to High Voltage Worlds are the sole property of High Voltage
        Worlds, LLC. Once a hvWorlds NFT is sold, burned, or transferred, you no
        longer retain the Commercial Use License. High Voltage Worlds, LLC and
        its officers retain the right to revoke Commercial Use rights and
        license if the usage is determined to be obscene, illegal, detrimental
        to the community, or detrimental to the High Voltage Worlds brand.
      </article>
      <article className="text-center subheading mb-5 terms-bold  col-12">
        For the sake of clarity, nothing in this Section will be deemed to
        restrict you from:
      </article>
      <article className="text-center subheading terms-bold mb-5 col-12">
        <ol style={{ textAlign: "left" }}>
          <li>
            owning or operating a marketplace that permits the use and sale of
            hvWorlds NFT generally, provided said marketplace cryptographically
            verifies each hvWorlds NFT owner’s rights to display the Art for
            their hvWorlds NFT to ensure that only the actual owner can display
            the Art;
          </li>
          <li>
            owning or operating a third party website or application that
            permits the inclusion, involvement, or participation of hvWorlds NFT
            generally, provided that the third party website or application
            cryptographically verifies each hvWorlds NFT owner’s rights to
            display the Art for their hvWorlds NFT to ensure that only the
            actual owner can display the Art, and provided that the Art is no
            longer visible once the owner of the purchased hvWorlds NFT leaves
            the website/application; or
          </li>

          <li>earning revenue from any of the foregoing.</li>
        </ol>
      </article>

      <header
        style={{ color: "white" }}
        className="text-center mb-3 text-uppercase title2"
      >
        INDEMNITY
      </header>
      <article className="text-center subheading mb-5 col-12">
        You shall defend, indemnify and hold harmless High Voltage Worlds, LLC
        or High Voltage, LLC and its subsidiaries, affiliates, officers, agents,
        employees, advertisers, licensors, suppliers or partners from and
        against any claim, liability, loss, damage (actual and consequential) of
        any kind or nature, suit, judgment, litigation cost, and attorneys’ fees
        arising out of or in any way related to (i) your breach of these Terms,
        (ii) your use or misuse of, or access to the App, (iii) misappropriation
        or infringement by you, of any intellectual property rights or other
        right of hvWorlds NFT, or any person or entity, (iv) your violation of
        applicable laws, rules or regulations in connection with your access to
        or use of the App, or (v) damages (actual and consequential) sustained
        as a result of third-parties in association with a hvWorlds NFT. We
        reserve the right to assume the exclusive defense and control of any
        matter otherwise subject to indemnification by you, in which event you
        will assist and cooperate with us in asserting any available defenses.
      </article>
    </div>
  );
}

export default Ownership;
