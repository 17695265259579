import React from "react";
import "./styles/five-families-styles.css";
const FiveFamilies = () => {
  const images = [];
  images[0] = "./assets/The 5 Families/352x352_Punk_Promo_4.png";
  images[1] = "./assets/The 5 Families/352x352 Mask Promo 3.jpg";
  images[2] = "./assets/The 5 Families/352x352 Desert Promo 3.jpg";
  images[3] = "./assets/The 5 Families/352x352 Tundra Promo 2.jpg";
  images[4] = "./assets/The 5 Families/352x352_Ape_Promo_2.png";
  return (
    <div
      id="five-families"
      className="main-outer-container d-flex justify-content-center row py-3 even-section"
    >
      <header className="text-center mb-3 title2">OUR COMMUNITY</header>
      <article className="text-center subheading mb-5 col-10">
        Each NFT is automatically part of a family that unlocks special perks.
        Each family is inspired from other famous NFT projects. Each family
        starts with an owner-exclusive Discord server to meet other holders.
        Families will be able to participate in large scale community events and
        win Solana prizes,
      </article>
      <article className="text-center subheading subheading2 mb-5 col-sm-12">
        READY TO BE A PART OF THE FAMILY?{" "}
        <span className="join-discord">
          <a href="">JOIN THE DISCORD</a>
        </span>
      </article>
      <section className="container">
        <section className="row d-flex justify-content-center">
          <section className="col-lg-3 col-md-5 col-sm-8 mb-3">
            <img className="card-image" src={images[0]} alt="" />
            <section className="text-uppercase mt-2">
              <article className="card-heading d-flex justify-content-center ">
                Punks of Cryptocity
              </article>
              <p className="card-description d-flex justify-content-center ">
                Inspired by Cryptopunks
              </p>
            </section>
          </section>
          <section className="col-lg-3 col-md-5 col-sm-8 mb-3">
            <img className="card-image" src={images[1]} alt="" />
            <section className="text-uppercase mt-2">
              <article className="card-heading d-flex justify-content-center">
                Masks of the Many Faced Forest
              </article>
              <p className="card-description d-flex justify-content-center">
                Inspired by Hashmasks
              </p>
            </section>
          </section>
          <section className="col-lg-3 col-md-5 col-sm-8 mb-3">
            <img className="card-image" src={images[2]} alt="" />
            <section className="text-uppercase mt-2">
              <article className="card-heading d-flex justify-content-center">
                Cats of the Golden Sands
              </article>
              <p className="card-description d-flex justify-content-center">
                Inspired by Coolcats
              </p>
            </section>
          </section>
        </section>
        <section className="row d-flex justify-content-center">
          <section className="col-lg-3 col-md-5 col-sm-8 mb-3">
            <img className="card-image" src={images[3]} alt="" />
            <section className="text-uppercase mt-2">
              <article className="card-heading d-flex justify-content-center">
                Penguins of Frosty Mountains
              </article>
              <p className="card-description d-flex justify-content-center">
                Inspired by Pudgy Penguins
              </p>
            </section>
          </section>
          <section className="col-lg-3 col-md-5 col-sm-8 mb-3">
            <img className="card-image" src={images[4]} alt="" />
            <section className="text-uppercase mt-2">
              <article className="card-heading d-flex justify-content-center">
                Apes of Sunrise Isles
              </article>
              <p className="card-description d-flex justify-content-center">
                Inspired by Bored Ape Yacht Club
              </p>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
};

export default FiveFamilies;
