import React from "react";
import "./styles/terms-styles.css";
function Privacy() {
  return (
    <div
      id="privacy-policy"
      className="main-outer-container d-flex justify-content-center row py-5 even-section"
    >
      <header
        style={{ color: "white" }}
        className="text-center mb-3 text-uppercase title2"
      >
        Privacy POLICY
      </header>
      <article className="text-center subheading mb-5 col-12">
        Thank you for choosing to be part of the High Voltage Worlds community.
        We are committed to protecting your privacy and, for that reason, we
        have adopted this Privacy Policy to explain our data collection, use,
        and disclosure practices for our websites, highvoltageworlds.io and all
        related sites, software, and services provided by us that link to or
        reference this Privacy Policy (collectively, the “High Voltage
        Platform”). The High Voltage Worlds Platform is owned and operated by
        High Voltage Worlds, LLC (“High Voltage Worlds”, “we”, “us” or “our”).
      </article>
      <article className="text-center subheading mb-5 col-12">
        This Privacy Policy applies to information High Voltage Worlds collects
        through the High Voltage Worlds Platform, as well as other information
        provided to us online or offline by third parties when we associate that
        information with users of the High Voltage Worlds Platform (but not to
        information collected from our employees, contractors, or vendors).
        Certain third parties may also be able to collect information via the
        High Voltage Worlds Platform. The data collection and use practices of
        these third parties may be governed by those third parties’ own
        policies, including their own privacy policies, as further described in
        Sections 2(A), 4, and 5, below.
      </article>
      <article className="text-center subheading mb-5 col-10">
        This Privacy Policy describes, among other things:
        <ul style={{ textAlign: "left" }}>
          <li>Personal and other information we collect about you;</li>
          <li>How we use your information;</li>
          <li>How we may share your information with third parties; and</li>
          <li>
            Your choices regarding the personal information we collect about
            you.
          </li>
        </ul>
      </article>
      <article className="text-center subheading mb-5  privacy-heading col-12">
        1. CONSENT
      </article>
      <article className="text-center subheading mb-5 col-12">
        By accessing or using the High Voltage Worlds Platform, you consent to
        this Privacy Policy. If you do not agree with this Privacy Policy,
        please do not access or use the High Voltage Platform. Information
        gathered through the High Voltage Worlds Platform may be transferred,
        used, and stored in the United States or in other countries where our
        service providers, our affiliates or we are located. If you use the High
        Voltage Worlds Platform, you agree to the transfer, use, and storage of
        your Personal Information (as defined below) in those countries. The
        data protection and other laws of the United States and other countries
        might not be as comprehensive as those in your country. You agree that
        all transactions relating to the High Voltage Worlds Platform or High
        Voltage Worlds are deemed to occur in the United States, where our
        servers are located.
      </article>
      <article className="text-center subheading mb-5 col-12">
        European Union Residents<br></br>
        <br></br>
        If you reside in a country in the European Economic Area or in
        Switzerland, then information we collect from you may be subject to
        Regulation (EU) 2016/679 of the European Parliament and of the Council
        of 27 April 2016 on the protection of natural persons with regard to the
        processing of personal data and on the free movement of such data, and
        repealing Directive 95/46/EC (the “GDPR”) and the following additional
        information is provided for your benefit.
      </article>
      <article className="text-center subheading mb-5 col-12">
        The controller of the personal data collected through the High Voltage
        Worlds Platform is:
        <br></br>
        High Voltage Worlds, LLC
        <br></br>
        If you use the High Voltage Worlds Platform, you acknowledge that your
        personal data is being processed pursuant to the following lawful bases:
        your consent, High Voltage Worlds’s legitimate interests, to fulfill
        requests made by you, and compliance with applicable law. You further
        acknowledge and consent to your information that is gathered through the
        High Voltage Worlds Platform being transferred to, used in, and stored
        in the United States.
      </article>
      <article className="text-center subheading mb-5 col-12">
        You hereby consent to the processing of your personal data as described
        in this Privacy Policy, including the transfer, use, and storage of your
        personal data in the United States. The data protection and other laws
        of the United States and other countries might not be as comprehensive
        as those in your country. You may withdraw your consent at any time by
        following the instructions in the Accessing/Updating/Deleting Your
        Personal Information section below, but this will not affect the
        lawfulness of processing based on consent before its withdrawal.
        However, if you withdraw your consent or decline to provide personal
        data to us, you may be unable to use the High Voltage Worlds Platform.
        You have the right to file a complaint relating to the processing of
        personal data with a supervisory authority.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Accessing/Updating/Deleting Your Personal Information
        <br></br>
        <br></br>
        If you would like to delete, update, or access the Personal Information
        we have collected from you, and/or deactivate or delete your High
        Voltage Worlds account, you may do so by sending an email to
        admin@highvoltageworlds.technology. Additionally, if your Personal
        Information is subject to the GDPR, you may have certain additional
        rights, including the right of restriction of processing of personal
        data, and the right to object to the processing of personal data. Please
        send e-mail to admin@highvoltageworlds.technology for more information.
      </article>

      <article className="text-center subheading mb-5 privacy-heading  col-12">
        2. Collection of Your Personal and Other Information
      </article>
      <article className="text-center subheading mb-5 col-12">
        When you register for, or use the High Voltage Worlds Platform, we
        collect Personal Information. By “Personal Information” we mean
        information that can identify an individual, such as:
        <ul style={{ textAlign: "left", marginTop: "15px" }}>
          <li>Email address</li>
          <li>Your Solana Address</li>
          <li>Your Solana Wallet Address</li>
          <li>Your Internet Protocol (IP address)</li>
          <li>Discord username</li>
        </ul>
      </article>
      <article className="text-center subheading mb-5 col-12">
        The High Voltage Worlds Platform may integrate with social media and
        other third-party platforms such as Discord. When you connect your
        account from a third-party platform to the High Voltage Worlds Platform,
        then we may collect information from that third-party platform and share
        information with that third-party platform. This collected information
        may include, but is not limited to, your name, email address,
        demographic information from your profile, or other content. You
        acknowledge and agree that High Voltage Worlds is not responsible for
        the data collection or use practices of any such connected third-party
        platform. You should read each third-party platform’s privacy policy
        before connecting that third-party account.
      </article>
      <article className="text-center subheading mb-5 col-12">
        You may choose not to provide Personal Information, but this may prevent
        you from receiving certain features of the High Voltage Worlds Platform.
      </article>
      <article className="text-center subheading mb-5 col-12">
        We may also collect non-Personal Information relating to the High
        Voltage Worlds Platform, that is, information that does not personally
        identify an individual. The non-Personal Information we may collect
        includes how you interact with the High Voltage Worlds Platform,
        information generally collected or “logged” by Internet websites or
        Internet services when accessed or used by users, and information about
        your web browser or device accessing or using the High Voltage Worlds
        Platform.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Examples of the non-Personal Information we may collect are:
        <br></br>
        <br></br>
        <ul style={{ textAlign: "left" }}>
          <li>
            Information about your computer or mobile device, such as its maker
            or operating system version, and HTTP header information
          </li>
          <li>The pages of our website that you viewed during a visit;</li>
          <li>
            What information, or content you view or interact with using the
            High Voltage Worlds Platform; and
          </li>
          <li>
            The city, state, province and country in which you are located;
          </li>
        </ul>
      </article>
      <article className="text-center subheading mb-5 col-12">
        We will not use non-Personal Information to try to identify you, and if
        we associate any non-Personal Information with information that
        personally identifies you, then we will treat it as Personal
        Information. As discussed in more detail below, we sometimes use cookies
        and other automatic information gathering technologies to gather
        non-Personal Information.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Information collected by the High Voltage Worlds Platform may be
        collected by us or one of our service providers, but in either case,
        this Privacy Policy will govern the collection, use, and sharing of the
        information.
      </article>

      <article className="text-center subheading mb-5  privacy-heading col-12">
        3. Use of Your Information
      </article>
      <article className="text-center subheading mb-5 col-12">
        <ul style={{ textAlign: "left" }}>
          <li>
            Assist us in providing, maintaining, and protecting the High Voltage
            Worlds Platform;{" "}
          </li>
          <li>
            Set up, maintain, and protect accounts to use the High Voltage
            Worlds Platform;
          </li>
          <li>Improve our online operations;</li>
          <li>Process transactions;</li>
          <li>Provide customer service; </li>
          <li>
            Communicate with you, such as provide you with account or
            transaction-related communications, or other newsletters, and/or
            other communications relating to the High Voltage Worlds Platform;
          </li>
          <li>
            Send or display offers and other content that is customized to your
            interests or preferences;
          </li>
          <li>
            Perform research and analysis aimed at improving our products and
            services and developing new products or services; and
          </li>
          <li>
            Manage and maintain the systems that provide the High Voltage Worlds
            Platform.
          </li>
        </ul>
      </article>

      <article className="text-center subheading mb-5  privacy-heading col-12">
        4. Disclosure of Your Information
      </article>
      <article className="text-center subheading mb-5 col-12">
        We may disclose your Personal Information to third parties as described
        below.
      </article>
      <article className="text-center subheading mb-5 col-12">
        We may disclose Personal Information to provide services to you, or when
        you authorize or instruct us to do so.
      </article>
      <article className="text-center subheading mb-5 col-12">
        We may disclose Personal Information and non-Personal Information to
        those companies, agents, contractors, service providers, or others
        engaged to perform functions on our behalf (such as provision of data
        storage, hosting of our website, conducting audits, and performing web
        analytics).
      </article>
      <article className="text-center subheading mb-5 col-12">
        We may license third party software to include in or use with the High
        Voltage Worlds Platform, in which case we may disclose Personal
        Information and/or Non-Personal Information to the licensor. These third
        parties’ collection and use of Personal Information and non-Personal
        Information are subject to their own privacy policies, which you can
        read here:
        <ul style={{ textAlign: "left", marginTop: "15px" }}>
          <li>Google Analytics </li>
          <li>AWS</li>
          <li>Discord</li>
        </ul>
      </article>
      <article className="text-center subheading mb-5 col-12">
        We may also disclose Personal Information to other third parties upon
        your consent to such disclosures.
      </article>
      <article className="text-center subheading mb-5 col-12">
        In addition, we may disclose your Personal Information to third parties
        when we believe, in good faith and in our sole discretion, that such
        disclosure is reasonably necessary to (a) enforce or apply the terms and
        conditions of the High Voltage Worlds Platform, including investigation
        of potential violations thereof, (b) comply with legal or regulatory
        requirements or an enforceable governmental request, (c) protect the
        rights, property or safety of High Voltage Worlds, our users or other
        third parties, (d) prevent a crime or protect national security, or (e)
        detect, prevent or otherwise address fraud, security or technical
        issues.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Finally, we reserve the right to transfer information (including your
        Personal Information) to a third party in the event of a sale, merger,
        or transfer of all or a portion of the assets of our company relating to
        the High Voltage Worlds Platform, or in the event of a bankruptcy,
        liquidation, or receivership of our business. We will use commercially
        reasonable efforts to notify you of such transfer, for example via email
        or by posting notice on our website.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Lastly, we may also disclose non-Personal Information, aggregated with
        information about our other users, to our business partners, merchants,
        investors, potential buyers and other third parties if we deem such
        disclosure, in our sole discretion, to have sound business reasons or
        justifications.
      </article>

      <article className="text-center subheading mb-5  privacy-heading col-12">
        5. Cookies and Automatic Information Gathering Technologies
      </article>
      <article className="text-center subheading mb-5 col-12">
        Lastly, we may also disclose non-Personal Information, aggregated with
        information about our other users, to our business partners, merchants,
        investors, potential buyers and other third parties if we deem such
        disclosure, in our sole discretion, to have sound business reasons or
        justifications.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Every time you use the High Voltage Worlds Platform we collect
        non-Personal Information (discussed above in Section 2) regarding that
        use. For example, to improve the High Voltage Worlds Platform, we
        collect how, when, and which parts of the High Voltage Worlds Platform
        or its features you use, which third-party platforms you connect to the
        High Voltage Worlds Platform. Also, we may use your device’s unique
        identifier (UDID), media access control address (MAC Address), or other
        unique identifiers to assist us in collecting and analyzing this data.
      </article>
      <article className="text-center subheading mb-5 col-12">
        To assist us in collecting and storing this non-Personal Information, we
        may employ a variety of technologies, including “Cookies,” local browser
        storage, and “Web Beacons.” A “Cookie” is a small amount of data a
        website operator, or a third party whose content is embedded in that
        website, may store in your web browser and that the website operator or,
        as applicable, the third party, can access when you visit the website. A
        Cookie may also refer to web-browser-based storage provided by Adobe’s
        Flash plugin (a “Flash Cookie”). High Voltage Worlds Platform Cookies
        may contain user preferences, unique identifiers, and other non-Personal
        Information. A “Web Beacon” is a small, usually transparent image placed
        on a web page that allows the operator of that image, which may be the
        operator of the website you visit or a third party, to read or write a
        Cookie.
      </article>
      <article className="text-center subheading mb-5 col-12">
        We and/or vendors may use Cookies to track the movements of individual
        users through the Misfit University Platform and elsewhere on the web or
        across apps, devices, and geographic locations, to help diagnose
        problems with servers, to gather broad demographic information, to
        conduct research, to record registration and/or personalization
        information, and to otherwise administer the High Voltage Worlds
        Platform.
      </article>
      <article className="text-center subheading mb-5 col-12">
        In addition, High Voltage Worlds may use Cookies in our communications
        with you, such as within email to determine which messages have been
        opened by recipients.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Your operating system and web browser may allow you to erase information
        stored in Cookies, Flash Cookies, and local browser storage. But if you
        do so, you may be forced to login to the High Voltage Worlds Platform
        again (if applicable) and you may lose some preferences or settings. You
        may also be able to set your browser to refuse all website storage or to
        indicate when it is permitted, but some features of the High Voltage
        Worlds Platform may not function properly without it. We may use Cookies
        to keep you logged in, save your preferences for the High Voltage Worlds
        Platform, and to collect information about how you use the High Voltage
        Worlds Platform.
      </article>
      <article className="text-center subheading mb-5 col-12">
        More information about managing Cookies is available here. Cookie
        management tools provided by your browser may not affect Flash Cookies.
        More information about managing Flash Cookies is available here. To
        learn how to manage privacy and storage settings for your local browser
        storage, please refer to the end user documentation for your browser.
      </article>

      <article className="text-center subheading privacy-heading  mb-5 col-12">
        6. Transparency and Choice
      </article>
      <article className="text-center subheading mb-5 col-12">
        You may request access to your Personal Information by sending an email
        to admin@highvoltageworlds.technology We will try to locate and provide
        you with your Personal Information and give you the opportunity to
        correct this data, if it is inaccurate, or to delete it, at your
        request. But, in either case, we may need to retain it for legal reasons
        or for legitimate business purposes.
      </article>
      <article className="text-center subheading mb-5 col-12">
        We ask individual users to identify themselves and the information
        requested to be accessed, corrected, or removed before processing such
        requests, and we may decline to process requests that are unreasonably
        repetitive or systematic, require disproportionate technical effort,
        jeopardize the privacy of others, would be extremely impractical (for
        instance, requests concerning information residing on backups), or
        relate to information that is not associated with your Personal
        Information. In any case, where we provide information access and
        correction, we perform this service free of charge, except if doing so
        would require a disproportionate effort.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Please be aware that if you request us to delete your Personal
        Information, you may not be able to continue to use the High Voltage
        Worlds Platform. Also, even if you request that we delete your Personal
        Information, we may need to retain certain information for a limited
        period of time to satisfy our legal, audit and/or dispute resolution
        requirements.
      </article>
      <article className="text-center subheading mb-5 col-12">
        You can opt out of receiving marketing emails from us by clicking on the
        “unsubscribe” link in the e-mails. Please note that it may take up to
        ten (10) business days for your opt-out request to be processed. Also,
        even if you opt out of marketing emails, we may continue to send you
        certain account-related emails, such as notices about your account and
        confirmations of transactions you have requested.
      </article>

      <article className="text-center subheading privacy-heading  mb-5 col-12">
        7. Children
      </article>
      <article className="text-center subheading mb-5 col-12">
        The High Voltage Worlds Platform is not intended for users under 13
        years of age. We do not knowingly collect Personal Information from
        users under 13 years of age. We do not authorize users under 13 years of
        age to use the High Voltage Worlds Platform.
      </article>

      <article className="text-center subheading privacy-heading  mb-5 col-12">
        8. Information Security
      </article>
      <article className="text-center subheading mb-5 col-12">
        We utilize reasonable information security measures to safeguard your
        Personal Information against unauthorized access, modification, or
        destruction. However, no data transmission over the Internet and no
        method of data storage can be guaranteed to be 100% secure. Therefore,
        while we strive to use commercially acceptable means to protect your
        Personal Information, we cannot guarantee its security.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Within High Voltage Worlds, we restrict access to Personal Information
        to our employees, contractors, and agents who need to know that
        information in order to operate, develop, improve or support the High
        Voltage Worlds Platform. If we share Personal Information with service
        providers, we require that they also use reasonable information security
        measures to safeguard your Personal Information and only use your
        Personal Information for the purposes for which we share it with them.
      </article>

      <article className="text-center subheading privacy-heading  mb-5 col-12">
        9. California Privacy Rights
      </article>

      <article className="text-center subheading mb-5 col-12">
        California Civil Code Section 1798.83 permits our users who are
        California residents to request and obtain from us, once a year and free
        of charge, information about categories of personal information (if any)
        we disclosed to third parties for direct marketing purposes and the
        names and addresses of all third parties with which we shared personal
        information in the immediately preceding calendar year. If you are a
        California resident and would like to make such a request, please submit
        your request in writing to us using the contact information provided
        below.
      </article>
      <article className="text-center subheading mb-5 col-12">
        If you are under 18 years of age, reside in California, and have a
        registered High Voltage Worlds Platform account, you have the right to
        request removal of unwanted data that you publicly post on the High
        Voltage Worlds Platform. To request removal of such data, please contact
        us using the contact information provided below, and include the email
        address associated with your account and a statement that you reside in
        California. We will make sure the data is not publicly displayed on the
        High Voltage Worlds Platform, but please be aware that the data may not
        be completely or comprehensively removed from our systems.
      </article>

      <article className="text-center subheading privacy-heading  mb-5 col-12">
        10. Additional Privacy Rights
      </article>
      <article className="text-center subheading mb-5 col-12">
        You may request, up to two (2) times per year: (1) the categories and
        specific pieces of data that High Voltage Worlds has collected from you;
        (2) the categories of sources from which your data was collected; (3)
        the categories of third parties to which your data has been disclosed or
        sold; and (4) the purposes for which the information was disclosed or
        sold. If you would like to make such a request, please submit your
        request in writing to us using the contact information provided below.
      </article>
      <article className="text-center subheading mb-5 col-12">
        Subject to certain limitations, you have the right to (1) request to
        know more about the categories and specific pieces of personal
        information High Voltage Worlds collects, uses, and discloses about you,
        (2) request deletion of your Personal Information, (3) opt out of any
        sales of your Personal Information, and (4) not be discriminated against
        for exercising these rights. If you would like to make such a request,
        please submit your request in writing to us using the contact
        information provided below.
      </article>

      <article className="text-center subheading  privacy-heading mb-5 col-12">
        11. Third Party Websites
      </article>
      <article className="text-center subheading mb-5 col-12">
        Please note that the High Voltage Worlds Platform may link or integrate
        with third-party sites, services or apps. We are not responsible for the
        privacy or security policies or practices or the content of such third
        parties. Accordingly, we encourage you to review the privacy and
        security policies and terms of service of those third parties so that
        you understand how those websites collect, use, share and protect your
        information.
      </article>

      <article className="text-center subheading privacy-heading mb-5 col-12">
        12. Changes to this Policy
      </article>
      <article className="text-center subheading mb-5 col-12">
        We may modify or update this Privacy Policy periodically with or without
        prior notice by posting the updated policy on this page. You can always
        check the “Last Updated” date at the top of this document to see when
        the Privacy Policy was last changed. If we make any material changes to
        this Privacy Policy, we will notify you by reasonable means, which may
        be by e-mail or posting a notice of the changes on our website prior to
        the changes becoming effective. We encourage you to check this Privacy
        Policy from time to time.
      </article>
    </div>
  );
}

export default Privacy;
